<template>
  <div class="workflows" @drop.prevent @dragover.prevent>
    <v-toolbar flat>
      <v-toolbar-title>Nuevo Workflow</v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row justify="center" class="mx-8">
        <base-error :error="error"></base-error>
      </v-row>
      <v-form v-model="isValid" ref="form" @submit="submit" class="new__workflows__form ma-5">
        <v-row class="mb-2">
          <v-col class="pa-0">
            <v-text-field
              outlined
              :rules="rules.issueStatusName"
              label="*Nombre"
              class="mt-2"
              v-model="name"
              :error-messages="nameError"
              @keydown="nameError=null; error=null"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col class="pa-0">
            <v-text-field outlined name="description" label="Descripción" v-model="description"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col class="pa-0">
            <v-select
              outlined
              v-model="issueTypeId"
              item-value="id"
              item-text="name"
              :items="getIssueTypesIssueTypes"
              label="*Tipo de incidencia"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col @drop.prevent="addFile" @dragover.prevent>
            <v-row align="center">
              <v-col cols="2">
                <v-avatar size="88" color="grey">
                  <v-img v-if="iconUrl" :src="iconUrl"></v-img>
                  <v-icon v-else dark>mdi-image</v-icon>
                </v-avatar>
              </v-col>
              <v-col>
                <v-file-input
                  outlined
                  class="ml-3"
                  :rules="rules.issueTypeIcon"
                  show-size
                  v-model="icon"
                  accept="image/png, image/jpeg, image/bmp"
                  label="*Icono"
                  placeholder="Elige un ícono"
                  :loading="isIconLoading"
                  @change="onIconChange"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              rounded
              outlined
              block
              color="primary"
              class="mt-5"
              :to="{name: 'workflows'}"
            >Cancelar</v-btn>
          </v-col>
          <v-col>
            <v-btn
              rounded
              :loading="isLoading"
              type="submit"
              block
              color="primary"
              class="mt-5"
              :disabled="!isValid"
            >Guardar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import { to } from '@/helpers'
import rules from '@/constants/input-validation'
import {
  WORKFLOWS_CREATE_WORKFLOW,
  ISSUE_TYPES_GET_ISSUE_TYPES,
  FILES_UPLOAD_FILE
} from '@/store/actions.type'
import { errorMessage } from '@/mixins'
import { mapGetters } from 'vuex'
export default {
  name: 'IssueStatusNew',
  props: {},
  created() {
    this.getIssueTypesTypes()
  },
  data() {
    return {
      rules,
      isValid: false,
      isLoading: false,
      description: '',
      descriptionError: '',
      name: '',
      nameError: '',
      issueTypeId: null,
      issueTypeError: '',
      iconUrl: null,
      icon: null,
      isIconLoading: false,
      error: null
    }
  },
  computed: {
    ...mapGetters(['getIssueTypesIssueTypes', 'getFilesFile'])
  },
  components: {},
  mixins: [errorMessage],
  methods: {
    back() {
      this.$router.push({ name: 'workflows' })
    },
    async getIssueTypesTypes() {
      this.isLoading = true
      const [err] = await to(this.$store.dispatch(ISSUE_TYPES_GET_ISSUE_TYPES))
      if (err) {
        this.error = err
        this.isLoading = false
      } else {
        this.isLoading = false
      }
    },
    async submit(evt) {
      evt.preventDefault()
      const workflow = {
        name: this.name,
        description: this.description,
        issueType: {
          id: this.issueTypeId
        },
        iconId: this.getFilesFile.id
      }
      this.isLoading = true
      this.error = null
      const [err, data] = await to(
        this.$store.dispatch(WORKFLOWS_CREATE_WORKFLOW, workflow)
      )
      if (err) {
        this.error = err
        this.isLoading = false
        if (err.status === 422) {
          this.nameError = this.errorMessage(err, 'name')
          this.descriptionError = this.errorMessage(err, 'description')
        }
      } else {
        this.$router.push({
          name: 'workflows-edit',
          params: { workflowId: data.id }
        })
      }
    },
    async createAndUpload() {
      this.isIconLoading = true
      this.error = null
      const file = {
        file: this.icon,
        type: 'icons'
      }

      const [err] = await to(this.$store.dispatch(FILES_UPLOAD_FILE, file))
      if (err) {
        this.error = err
        this.isIconLoading = false
        if (err.status === 422) {
          this.fileError = this.errorMessage(err, 'type')
        }
      } else {
        this.isIconLoading = false
      }
    },
    onIconChange(file) {
      if (!file) {
        this.iconUrl = null
      }
    },
    addFile(e) {
      const droppedFile = e.dataTransfer.files[0]
      const admittedFileTypes = ['image/png', 'image/jpeg', 'image/bmp']
      if (!droppedFile) return
      if (admittedFileTypes.includes(droppedFile.type)) {
        this.icon = droppedFile
      }
    }
  },
  watch: {
    icon: function() {
      if (this.icon) {
        this.createAndUpload()
      }
    },
    getFilesFile: function() {
      if (this.getFilesFile.url) {
        this.iconUrl = this.getFilesFile.url
      }
    }
  }
}
</script>
